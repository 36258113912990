// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  casos: "https://run.mocky.io/v3/cb2fb2b7-e287-4af1-ae6a-abee27b381ad",
  led_api: "https://dev-pled.pjm.gob.ar/apirest/v1",
  //audiencias_api: "https://hwapis.pjm.gob.ar/audiencias-api/api/expte_api.php",
  audiencias_api: 'https://dev-api.audienciaspenal.pjm.gob.ar',
  KC_URL: "https://auth.pjm.gob.ar/auth/",
  KC_CLIENT_ID: "consulta-led",
  KC_REALM: "registered",
  LOGIN_HOME_ENCODED: "http%3A%2F%2Flocalhost%3A4603",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
